import React, { useState } from "react";
import Banner from "./Banner";
import Footer from "./Footer";
import Header from "./Header";
import Products from "./Products";
import PromoBanner from "./promoBanner";
import { fetchSalesBanner } from "../shared/checkout";

export default function Partner({ partner, header, shipping, logo }) {
  const [promoMessage, setPromoMessage] = useState(null);

  if (!promoMessage) {
    fetchSalesBanner().then(response => {
      console.log(response);
      if(response){
        if (partner.toLowerCase() === 'gympass') {
          //setPromoMessage(response[1].salesBannerCopy);
          //console.log(response);
        } else {
          setPromoMessage(response[0].salesBannerCopy);
        }
      }
    })
  }

  return (
    <div>
      {!promoMessage ? null :
        <PromoBanner message={promoMessage} />
      }
      <Banner partner={partner} />
      <Header partner={partner} header={header} logo={logo} freeShipping={shipping} />
      <div className="products-wrapper">
        <Products partner={partner} freeShipping={shipping} />
      </div>
      <Footer />
    </div>
  );
}