import React, { useState } from "react";
import { useCookies } from "react-cookie";
import {
  getConnectedProducts,
  createCheckout,
  updateCart,
  clearCart,
  fetchCheckoutCookie,
  discountAdd,
  getFitPass,
  addCheckoutAttribut,
  checkForFreeShipping
} from "../shared/checkout";
import Cart from "./Cart";
import Product from "./Product";

export default function Products({ partner, freeShipping }) {
  const [products, setProducts] = useState(null);
  const [fitPass, setFitPass] = useState(null);
  const [productCount,  setProductCount] = useState(0);
  const [checkoutId, setCheckoutId] = useState(null);
  const [checkoutUrl, setCheckoutUrl] = useState(null);
  const [cart, setCart] = useState(null);
  const [cartCount, setCartCount] = useState(null);
  // const [checkout, setCheckout] = useState(null);
  const [toggleCart, setToggleCart] = useState(false);
  const [showMembership, setShowMembership] = useState(false);
  const [cookies, setCookie] = useCookies(["user"]);
  // const [saleItems, setSaleItems] = useState(false);
  const [isStrideInCart, setIsStrideInCart] = useState(false);
  const [isIpadInCart, setIsIpadInCart] = useState(false);

  const [addingToCart, setAddingToCart] = useState(false);

  //const saleProducts = ['Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDgwMDEzNDMzMjYxNQ==', 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDgwMDEzNDI5OTg0Nw==', 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDgwMDEzNDM2NTM4Mw==', 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDgwMDE0ODA5NTE3NQ=='];
  const hasFreeShipping = freeShipping;
  const discountCode = "EH0NJW72MF6G";
  //const saleDiscountCode = "NDQS420M6F30";
  //const saleBikes = ["sPartner Echelon EX-3 Connect Bike", "sPartner Echelon EX-5 Connect Bike"];
  const bikesToRemove = ["Partner Echelon EX-3 Connect Bike", "Partner Echelon EX-5 Connect Bike"];
  const bcbsCheck = window.location.href.includes('bcbs');
  const boaCheck = window.location.href.includes('bank-of-america');

  let partnerNameActual;
  let plan;
  // let isBCBS = false;

  let monthlyMembership;
  let yearlyMembership;
  let twoYearMembership;



  let noMembership;
  
  async function initialLoad() {
    await getConnectedProducts().then((response) => {
      if (partner.toLowerCase() === "gympass") {
        
        // Originally only showed bikes, gympass shows all except fitpass now.
        // const prods = response.filter(p => {
        //   return p.productType === "Connect Bike" && !saleBikes.includes(p.title);
        // })
        // setProducts(prods);
        // setProductCount(prods.length)

        setProducts(response.filter((p) => !bikesToRemove.includes(p.title)));
        setProductCount(response.filter((p) => !bikesToRemove.includes(p.title)).length);
      } else {
        setProducts(response.filter((p) => !bikesToRemove.includes(p.title)));
        setProductCount(response.filter((p) => !bikesToRemove.includes(p.title)).length);
      }
    })
    .then(() => {
      getFitPass().then((response) => {
        setFitPass(response);
      });
    })
    .then(() => {
      checkCheckoutCookie();
      checkForCookie();
    })
    .catch((err) => console.error(err));
  }

  if (!products) {
    initialLoad();
  }

  // Check if a checkout already exists
  async function checkCheckoutCookie() {
    if (cookies.partnerCheckout) {
      await fetchCheckoutCookie(cookies.partnerCheckout).then((response) => {
        setCheckoutId(response.id);
        setCheckoutUrl(response.webUrl);
        setCart({ total: response.subtotalPrice, lineItems: response.lineItems, attributes: response.customAttributes });
        setCartCount(response.lineItems.length);
      });
    } else {
      createCheckout().then((response) => {
        setCheckoutId(response.id);
        setCheckoutUrl(response.webUrl);
        setCart({ total: response.subtotalPrice, lineItems: response.lineItems, attributes: response.customAttributes });
        setCookie("partnerCheckout", response.id, { path: "/" })
      });
    }
  }

  // standard premier memberships
  const monthly =
  "gid://shopify/ProductVariant/43246953267399";
  const yearly = "gid://shopify/ProductVariant/30135749771346";
  const twoYears =
  "gid://shopify/ProductVariant/32930539503698";

  /*bcbs specific*/
  const bmonthly =
  "gid://shopify/ProductVariant/43246953267399";
  const byearly = "gid://shopify/ProductVariant/43241849061575";
  const btwoYears =
  "gid://shopify/ProductVariant/43241846800583";

  // Humnana memberships (not used?)
  const humanaMonthly =
  "gid://shopify/ProductVariant/32847091761234";
  const humanaYearly =
  "gid://shopify/ProductVariant/32847106342994";
  const humana2Years =
  "gid://shopify/ProductVariant/32847100444754";

  // Carolina Herrera memberships (not used?)
  const chnyMonthly =
  "gid://shopify/ProductVariant/41378285224135";
  const chnyYearly =
  "gid://shopify/ProductVariant/41378285781191";

  // check if user is on an affiliate page, if so call get partner name or get bcbs planid function
  function checkIfPartnerPage() {
    const isBrowser = typeof window != 'undefined';
    if (isBrowser) {
      let partnerUrl;

      if (window.location.href.includes('affiliate') && !window.location.href.includes('planid')) {
        partnerUrl = /affiliate(.*)/; 
      } else if (window.location.href.includes('echelon-marketplace') && !window.location.href.includes('planid')) {
        partnerUrl = /echelon-marketplace(.*)/; 
      } else {
        // isBCBS = true;
        partnerUrl = /planid=(.*)/; 
      }
      getPartnerName(partnerUrl);
    }
  }

  if (!partnerNameActual) {
    checkIfPartnerPage();
  }

  // check url and assign partner name
  function getPartnerName(partnerUrl) {
    const currentPage = window.location.href;
    const partnerName = partnerUrl.exec(currentPage);
    partnerNameActual = partnerName[1].replace("/", "");
    plan = partnerNameActual.replace(/\\|\//g, "");
  }

  // add custom attribute to the checkout object
  const addAttribut = () => {
    let input;
    input = {customAttributes: [{key: "plan-id", value: plan}]}

    // if (isBCBS) {
    // } else {
    //   input = {customAttributes: [{key: "partner", value: plan}]}
    // }

    // console.log(input);
 
    addCheckoutAttribut(checkoutId, input);
  }

  addAttribut();

  // checks for existing cookie if no cookie set cookie
  const checkForCookie = () => {
    if (cookies.Partner) {
      setCheckoutUrl(`${checkoutUrl}&ref= ${plan}`);
      setCookie("Partner", plan, { path: "/" });
    } else {
      setCheckoutUrl(`${checkoutUrl}&ref= ${plan}`);
      setCookie("Partner", plan, { path: "/" });
    }
  }

  if (partnerNameActual === "humana") {
    monthlyMembership = humanaMonthly;
    yearlyMembership = humanaYearly;
    twoYearMembership = humana2Years;
  } else if (partnerNameActual === "carolina-herrera") {
    monthlyMembership = chnyMonthly;
    yearlyMembership = chnyYearly;
  }  else if (partnerNameActual === "bcbs") {
    monthlyMembership = bmonthly;
    yearlyMembership = byearly;
    twoYearMembership = btwoYears;
  }
  else {
    monthlyMembership = monthly;
    yearlyMembership = yearly;
    twoYearMembership = twoYears;
  }

  const addToCart = async (lineItem) => {
    setAddingToCart(!addingToCart);
    let strideInCart = false;
    let iPadInCart = false;

    const isFreeShippingInCart = await checkForFreeShipping(checkoutId).then(response => response);
    // if partner != gympass and product is ex-3 or ex-5 set sale item true
    // if (partner !== "gympass" && saleProducts.includes(lineItem.variantId)) {
    //   setSaleItems(true);
    // }

if(iPadInCart){
  console.log('ipad in cart');
}

    // check if new item is a stride
    if (lineItem.variantId === 'gid://shopify/ProductVariant/40800169132231') {
      strideInCart = true;
      setIsStrideInCart(true);
    }

        // check if new item is a ipad
        if (lineItem.variantId === 'gid://shopify/CheckoutLineItem/412646694913992?checkout=0c08b217f2fb3af789412e91853f0c74') {
          iPadInCart = true;
          setIsIpadInCart(true);
        }


    if (strideInCart && isFreeShippingInCart) {
      // remove free shipping
      await clearCart(checkoutId, ['gid://shopify/CheckoutLineItem/326469018911540?checkout=4f54ae25f63c233b6d836b9ae024b298'])
      .then(response => {
        setCart({ total: response.subtotalPrice, lineItems: response.lineItems });
        showCartItems();
        setCartCount(response.lineItems.length);
      })
    }
    // lineItem obj .variantId .quantity
    await updateCart(lineItem, checkoutId).then((response) => {
      setCartCount(response.lineItems.length);
      setCart({ total: response.subtotalPrice, lineItems: response.lineItems });
      setAddingToCart(false);
      showCartItems();
    })
    .catch(error => console.error(error));

  };

  function emptyCart() {
    setAddingToCart(!addingToCart);
    const lineItemsToRemove = cart.lineItems.map(item => item.id);
    
    clearCart(checkoutId, lineItemsToRemove).then((response) => {
      showCartItems();
      setCart({ total: response.subtotalPrice, lineItems: response.lineItems });
      setCartCount(0);
    })
    .then(response => {
      setAddingToCart(false);
      return response
    })
    .catch(error => console.error(error));
  }

  const showCartItems = () => {
    setToggleCart(!toggleCart);
  };

  async function MembershipAdd(itemID, siteDeal) {

    const yearlyPlans = [monthly, yearly, twoYears];
    const isYearly = yearlyPlans.includes(itemID) ? true : false;
    const noPlan = itemID;

    console.log(`No Plan: ${noPlan}`);

    if (isYearly) {
      // if (!saleItems) {
      //   await discountRemove(checkoutId);
      // }
    } else {
      // if (saleItems) {
      //   await discountAdd(checkoutId, saleDiscountCode);
      // } else {
      //   await discountAdd(checkoutId, discountCode);
      // }
    }

    let Membership1;


    /*this is very quickly getting confusing*/

    if(noPlan === undefined){

      if(boaCheck){
        await discountAdd(checkoutId, "KRJ77ZY73Z5H");
      }else{
        await discountAdd(checkoutId, "85AK4QYJ6KY9");
      }
      
      window.location.href = checkoutUrl;
    }else{
      if (hasFreeShipping && !isStrideInCart) {  
        if (partnerNameActual !== 'gympass' && siteDeal !== 20 && isIpadInCart !== true) {
          
          
          {bcbsCheck ? 
            
            
            Membership1 = [
              {
                variantId: itemID,
                quantity: 1,
              },
              ]  
            
            : 
          
            Membership1 = [
              {
                variantId: itemID,
                quantity: 1,
              },
              ]
          
          }


          if(boaCheck){
            await discountAdd(checkoutId, "KRJ77ZY73Z5H");
          }else{
            await discountAdd(checkoutId, "85AK4QYJ6KY9");
          }



        } else if (partnerNameActual !== 'gympass' && siteDeal === 20 && isIpadInCart !== true) {
          Membership1 = [
            {
              variantId: itemID,
              quantity: 1,
            }
            
          ];


          if(boaCheck){
            await discountAdd(checkoutId, "KRJ77ZY73Z5H");
          }else{
            await discountAdd(checkoutId, "85AK4QYJ6KY9");
          }
          
        }else{
          Membership1 = [
            {
              variantId: itemID,
              quantity: 1,
            }
          ];
        //await clearCart(checkoutId, ['Z2lkOi8vc2hvcGlmeS9DaGVja291dExpbmVJdGVtLzQxMjY0NjY5NDkxMzk5Mj9jaGVja291dD0wYzA4YjIxN2YyZmIzYWY3ODk0MTJlOTE4NTNmMGM3NA==']);
        }
      } else {
        if (partnerNameActual !== 'gympass' && noPlan !== true) {
          Membership1 = [
            //ipad promo item
            /*{
              variantId:
                "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MTI2NDY2OTQ5MTM5OQ==",
              quantity: 1,
            },*/
            {
              variantId: itemID,
              quantity: 1,
            },
          ];

          if(boaCheck){
            await discountAdd(checkoutId, "KRJ77ZY73Z5H");
          }else{
            await discountAdd(checkoutId, "85AK4QYJ6KY9");
          }

        } else {
          Membership1 = [
            {
              variantId: itemID,
              quantity: 1,
            },
          ];
        }
      }

    }



    try {
      addToCart(Membership1)
      .then(() => {
        setShowMembership(false);
        showCartItems();
        window.location.href = checkoutUrl;
      });
      showCartItems();
    } catch {
      alert("There was a problem adding that item to your cart.");
    }
  }

  let totalSavings = 0;
  let subTotal = 0;
  let iPadPrice = false;
  let strikethrough;
  let discountPercent;

  if (partnerNameActual === "gympass" || partnerNameActual === "bcbs") {
    discountPercent = .15;
  }
  
  if (partnerNameActual === "bank-of-america") {
    discountPercent = .20;
  }


  //calculates total savings
  if (cartCount > 0) {
    cart.lineItems.map(
      (item) => {
        if (
          item.variant.id !== 'gid://shopify/ProductVariant/32988725641298'
          ) {


          strikethrough = item.variant.price.amount;

          const itemSavings = Number(strikethrough * item.quantity - 
            item.variant.price.amount * item.quantity + 
            item.variant.price.amount * item.quantity * discountPercent)
    
            // this is the total discount per lineItem)
            totalSavings = parseFloat(totalSavings + itemSavings);
          
          let itemSubTotals = Number(item.variant.price.amount * item.quantity - item.variant.price.amount * item.quantity * discountPercent)


          


          if(item.title.includes('Apple')){
            itemSubTotals = 0;
          }

          if(item.title.includes('Premier')){
            itemSubTotals = item.variant.price.amount;
          }
          
          console.log("SubTotal: "+ parseFloat(itemSubTotals));

            // this is the subtotal
            if(iPadPrice){
              subTotal += parseFloat(itemSubTotals);
            }else{
              subTotal += parseFloat(itemSubTotals);
            }
            
        }

        return 'done'
      }
    );
  }

  // this is the checkout function. it will check the cart for a membership before redirecting to checkoutURL.
  function checkCart() {
    // client.checkout.updateAttributes(cart.id, input);
    // UN-COMMENT WHEN SPRING PROMO ENDS
    // client.checkout.addDiscount(cart.id, discountCode);

    if (partnerNameActual === "gympass") {
      // REMOVE WHEN SPRING PROMO ENDS
      discountAdd(checkoutId, discountCode);
      const gympassInCart = cart.lineItems.filter(
        (item) =>
          item.id ===
          "gid://shopify/CheckoutLineItem/413782807349190?checkout=2587fb32a11ee02655435b5324a18f23"
      );

      if (gympassInCart.length > 0) {
        window.location.href = checkoutUrl;
      } else {
        let Membership1 = [
          {
            variantId:
              "gid://shopify/ProductVariant/41378280734919",
            quantity: 1,
          },
        ];
        try {
          addToCart(Membership1)
          .then(() => {
            showCartItems();
            window.location.href = checkoutUrl;
          });
        } catch {
          alert("There was a problem adding that item to your cart.");
        }
      }
    } else if (partnerNameActual === 'carolina-herrera') {
      // REMOVE WHEN SPRING PROMO ENDS
      discountAdd(checkoutId, discountCode);
      const chnyInCart = cart.lineItems.filter(
        // might be wrong id for chny monthly membership
        (item) => {
          return item.title === "Carolina Herrera Echelon Monthly Membership"
        }
      );

      if (chnyInCart.length > 0) {
        window.location.href = checkoutUrl;
      } else {
        let Membership1 = [
          {
            variantId: "gid://shopify/ProductVariant/41378285224135",
            quantity: 1
          }
        ];
        try {
          addToCart(Membership1)
          .then(() => {
            window.location.href = checkoutUrl;
            showCartItems();
          });
        } catch {
          alert("There was a problem adding that item to your cart.");
        }
      }
    } else if (
      cart.lineItems.length === 1 &&
      cart.lineItems[0].title.includes("FitPass")
    ) {
      window.location.href = checkoutUrl;
    } else {
      for (var i = 0; i < cart.lineItems.length; i++) {
        if (
          cart.lineItems[i].title.includes("Monthly") ||
          cart.lineItems[i].title.includes("Yearly") ||
          cart.lineItems[i].title.includes("Year")
        ) {
          setShowMembership(!showMembership);
          // window.location.href = checkoutUrl;
          // navigate(checkoutUrl);
        } else {
          noMembership = true;
          showCartItems();
          setShowMembership(true);
        }
      }
      if (noMembership) {
      }
    }
  }

  if (products === null) {
    return 'Loading....'
  }

  return (
    <div>
      <div className="cart" onClick={() => showCartItems()} role="button">
        <svg height="24" width="24" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shopping-cart" className="svg-inline--fa fa-shopping-cart fa-w-18 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
          <path fill="#fff" d="M528.12 301.319l47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z"></path>
        </svg>
        {cartCount > 0 ?
        <span className="cartIcon">
          {cartCount}
        </span>
        :
        null}
      </div>
      {addingToCart ? (
        <div className="adding-to-cart-container">
          <div className="spinner-wrapper">
            <div className="loader">Loading...</div>
          </div>
        </div>
      ) : null}
      {toggleCart ? (
        <Cart cart={cart} showCartItems={showCartItems} totalSavings={totalSavings} subTotal={subTotal} emptyCart={emptyCart} checkCart={checkCart} />
      ) : null}
      {showMembership ? 
      <div className="membership-container">
        <div className="membership-wrapper">
          {partnerNameActual === 'carolina-herrera' || partnerNameActual === 'gympass' ?
          null
          :
          <div className="membership-cards-header">
            <div className="cards-header">
              <div>
                 {/* Save an additional 5% off connected equipment with yearly membership */}
                 Select your choice of membership!
              </div>
            </div>
          </div>
          }
            <div className="membership-cards-container">
              {partnerNameActual === 'carolina-herrera' || partnerNameActual === 'gympass' ?
              null
              :
              <div className="cards-wrapper">

{bcbsCheck || boaCheck ? 

<div 
                  className="membership-card"
                  id="card-noplan"
                  tabIndex="0"
                  data-vid="noplan"
                  data-price="0"
                  data-term="No Plan"
              >
                <div className="card-header">
                  <h3 className="card-title">No Plan</h3>
                  <div className="tag most-popular">
                    Partner Exclusive
                  </div>
                  <div className="card-price">
                  </div>
                  <p className="card-billing-period"></p>
                </div>
                <div className="card-body">
                  <div className="card-content">
                    <p>
                    No plan at this time.
                    </p>
                  </div>
                </div>
                <div className="card-footer">                  

                  <button
                    id="add-noplan"
                    onClick={() =>
                      MembershipAdd(noMembership)
                    }
                    target="_self"
                    title="Choose Plan"
                    aria-label="Choose No Plan"
                    className="credera-snippet primary-button primary-button-with-icon"
                  >
                    {" "}
                    <span className="button-content buy-button" tabIndex="0">
                      {" "}
                      <span>No Plan</span>{" "}
                      <svg
                        fill="none"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {" "}
                        <path
                          d="m10 20c-1.9778 0-3.9112-0.5865-5.5557-1.6853s-2.9262-2.6606-3.6831-4.4879c-0.75688-1.8272-0.9549-3.8379-0.56905-5.7777s1.3383-3.7216 2.7368-5.1202c1.3985-1.3985 3.1803-2.3509 5.1201-2.7368s3.9504-0.18782 5.7777 0.56905c1.8273 0.75688 3.3891 2.0386 4.4879 3.6831 1.0988 1.6445 1.6853 3.5779 1.6853 5.5557 0 2.6522-1.0536 5.1957-2.929 7.0711-1.8753 1.8753-4.4188 2.9289-7.071 2.9289zm0-18.333c-1.6482 0-3.2593 0.48874-4.6297 1.4044s-2.4385 2.2172-3.0692 3.7399-0.79579 3.1982-0.47424 4.8147c0.32154 1.6165 1.1152 3.1014 2.2807 4.2669 1.1654 1.1654 2.6502 1.9591 4.2668 2.2806 1.6165 0.3216 3.2921 0.1565 4.8148-0.4742s2.8242-1.6989 3.7399-3.0693 1.4044-2.9815 1.4044-4.6297c0-2.2101-0.878-4.3298-2.4408-5.8926s-3.6824-2.4408-5.8925-2.4408zm4.9833 8.4008v0.0225c-0.0053 0.0736-0.0193 0.1463-0.0416 0.2167-0.0167 0.0291-0.0334 0.0533-0.05 0.0833-0.0287 0.0582-0.0652 0.1124-0.1084 0.1608-0.0281 0.0261-0.0588 0.0493-0.0916 0.0692l-2.1334 3.16c-0.1422 0.1886-0.3463 0.321-0.5764 0.3741-0.2301 0.053-0.4715 0.0232-0.6819-0.0841-0.1017-0.0394-0.1933-0.1011-0.2679-0.1807-0.0746-0.0795-0.1304-0.1749-0.1632-0.2789s-0.0418-0.2141-0.0263-0.3221c0.0155-0.1079 0.055-0.211 0.1157-0.3016l1.45-2.1525h-6.575c-0.22102 0-0.43302-0.0878-0.5893-0.2441s-0.24404-0.3683-0.24404-0.5893c0-0.22098 0.08776-0.43296 0.24404-0.58924s0.36828-0.24406 0.5893-0.24406h6.575l-1.45-2.1517c-0.0605-0.09066-0.0999-0.19376-0.1153-0.30169s-0.0065-0.21794 0.0263-0.32191 0.0886-0.19926 0.1631-0.27883 0.166-0.1414 0.2676-0.18092c0.2105-0.10685 0.4518-0.13634 0.6818-0.08333 0.23 0.053 0.434 0.18516 0.5765 0.37335l2.1167 3.135c0.0136 0.01067 0.025 0.02399 0.0333 0.03916 0.0719 0.06084 0.1337 0.13255 0.1834 0.21251 0.0546 0.09525 0.0859 0.20201 0.0916 0.31166v0.01332c0.0032 0.00376 0.0056 0.00816 7e-3 0.0129 0.0015 0.00473 0.0019 0.00971 0.0014 0.01462 0 0.01333 0.0083 0.02499 0.0083 0.03832 0 0.01331-0.0083 0.02581-0.0083 0.03831 0.0011 0.0105-0.0019 0.0209-0.0084 0.0292z"
                          clipRule="evenodd"
                          fill="#fff"
                          fillRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </button>
                </div>
              </div> :
null
}

                <div 
                  className="membership-card"
                  id="card-43246953267399-sitedeal"
                  tabIndex="0"
                  data-vid="43246953267399"
                  data-price="3999"
                  data-term="One Month"
                >
                  <div className="card-header">
                    <h3 className="card-title">Monthly</h3>
                    <div className="card-price">
                    $39.99<span>/mo</span>
                    </div>
                    <p className="card-billing-period">Billed Monthly</p>
                  </div>
                  <div className="card-body">
                    <div className="card-content">
                      <p>Most flexible</p>
                      {partnerNameActual !== 'carolina-herrera' ?
                      null
                      :
                      <p>
                        <strong>
                          ***Employer will reimburse employees 20%
                          of membership fee via payroll addition
                        </strong>
                      </p>
                      }
                    </div>
                  </div>
                  <div className="card-footer">
                  <button
                    id="add-43246953267399"
                    onClick={() =>
                      MembershipAdd(monthlyMembership, 20)
                    }
                    target="_self"
                    title="Choose Plan"
                    aria-label="Choose the Monthly Plan"
                    className="credera-snippet primary-button primary-button-with-icon"
                  >
                    {" "}
                    <span className="button-content buy-button" tabIndex="0">
                      {" "}
                      <span>Choose Plan</span>{" "}
                      <svg
                        fill="none"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {" "}
                        <path
                          d="m10 20c-1.9778 0-3.9112-0.5865-5.5557-1.6853s-2.9262-2.6606-3.6831-4.4879c-0.75688-1.8272-0.9549-3.8379-0.56905-5.7777s1.3383-3.7216 2.7368-5.1202c1.3985-1.3985 3.1803-2.3509 5.1201-2.7368s3.9504-0.18782 5.7777 0.56905c1.8273 0.75688 3.3891 2.0386 4.4879 3.6831 1.0988 1.6445 1.6853 3.5779 1.6853 5.5557 0 2.6522-1.0536 5.1957-2.929 7.0711-1.8753 1.8753-4.4188 2.9289-7.071 2.9289zm0-18.333c-1.6482 0-3.2593 0.48874-4.6297 1.4044s-2.4385 2.2172-3.0692 3.7399-0.79579 3.1982-0.47424 4.8147c0.32154 1.6165 1.1152 3.1014 2.2807 4.2669 1.1654 1.1654 2.6502 1.9591 4.2668 2.2806 1.6165 0.3216 3.2921 0.1565 4.8148-0.4742s2.8242-1.6989 3.7399-3.0693 1.4044-2.9815 1.4044-4.6297c0-2.2101-0.878-4.3298-2.4408-5.8926s-3.6824-2.4408-5.8925-2.4408zm4.9833 8.4008v0.0225c-0.0053 0.0736-0.0193 0.1463-0.0416 0.2167-0.0167 0.0291-0.0334 0.0533-0.05 0.0833-0.0287 0.0582-0.0652 0.1124-0.1084 0.1608-0.0281 0.0261-0.0588 0.0493-0.0916 0.0692l-2.1334 3.16c-0.1422 0.1886-0.3463 0.321-0.5764 0.3741-0.2301 0.053-0.4715 0.0232-0.6819-0.0841-0.1017-0.0394-0.1933-0.1011-0.2679-0.1807-0.0746-0.0795-0.1304-0.1749-0.1632-0.2789s-0.0418-0.2141-0.0263-0.3221c0.0155-0.1079 0.055-0.211 0.1157-0.3016l1.45-2.1525h-6.575c-0.22102 0-0.43302-0.0878-0.5893-0.2441s-0.24404-0.3683-0.24404-0.5893c0-0.22098 0.08776-0.43296 0.24404-0.58924s0.36828-0.24406 0.5893-0.24406h6.575l-1.45-2.1517c-0.0605-0.09066-0.0999-0.19376-0.1153-0.30169s-0.0065-0.21794 0.0263-0.32191 0.0886-0.19926 0.1631-0.27883 0.166-0.1414 0.2676-0.18092c0.2105-0.10685 0.4518-0.13634 0.6818-0.08333 0.23 0.053 0.434 0.18516 0.5765 0.37335l2.1167 3.135c0.0136 0.01067 0.025 0.02399 0.0333 0.03916 0.0719 0.06084 0.1337 0.13255 0.1834 0.21251 0.0546 0.09525 0.0859 0.20201 0.0916 0.31166v0.01332c0.0032 0.00376 0.0056 0.00816 7e-3 0.0129 0.0015 0.00473 0.0019 0.00971 0.0014 0.01462 0 0.01333 0.0083 0.02499 0.0083 0.03832 0 0.01331-0.0083 0.02581-0.0083 0.03831 0.0011 0.0105-0.0019 0.0209-0.0084 0.0292z"
                          clipRule="evenodd"
                          fill="#fff"
                          fillRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </button>
                  </div>
                </div>
                
                <div 
                  className="membership-card"
                  id="card-30135749771346"
                  tabIndex="0"
                  data-vid="30135749771346"
                  data-price="39999"
                  data-term="One Year"
              >
                <div className="card-header">
                  <h3 className="card-title">Yearly</h3>
                  <div className="tag most-popular">
                    Most Popular
                  </div>
                  <div className="card-price">
                  {bcbsCheck ? "$30" : "$33.33" }<span>/mo</span>
                  </div>
                  <p className="card-billing-period">Billed as {bcbsCheck ? "$359" : "$399" } a year</p>
                </div>
                <div className="card-body">
                  <div className="card-content">
                    <p>
                      Financing available for your <br />{" "}
                      equipment + membership bundle
                    </p>
                  </div>
                </div>
                <div className="card-footer">
                  {/* <button
                    id="add-30135749771346"
                    onClick={() =>
                      MembershipAdd(yearlyMembership)
                    }
                    target="_self"
                    title="Choose Plan"
                    aria-label="Choose the Yearly Plan"
                    className="credera-snippet primary-button primary-button-with-icon"
                  >
                    {" "}
                    <span className="button-content buy-button" tabIndex="0">
                      {" "}
                      <span>Plan + Free iPad</span>{" "}
                      <svg
                        fill="none"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {" "}
                        <path
                          d="m10 20c-1.9778 0-3.9112-0.5865-5.5557-1.6853s-2.9262-2.6606-3.6831-4.4879c-0.75688-1.8272-0.9549-3.8379-0.56905-5.7777s1.3383-3.7216 2.7368-5.1202c1.3985-1.3985 3.1803-2.3509 5.1201-2.7368s3.9504-0.18782 5.7777 0.56905c1.8273 0.75688 3.3891 2.0386 4.4879 3.6831 1.0988 1.6445 1.6853 3.5779 1.6853 5.5557 0 2.6522-1.0536 5.1957-2.929 7.0711-1.8753 1.8753-4.4188 2.9289-7.071 2.9289zm0-18.333c-1.6482 0-3.2593 0.48874-4.6297 1.4044s-2.4385 2.2172-3.0692 3.7399-0.79579 3.1982-0.47424 4.8147c0.32154 1.6165 1.1152 3.1014 2.2807 4.2669 1.1654 1.1654 2.6502 1.9591 4.2668 2.2806 1.6165 0.3216 3.2921 0.1565 4.8148-0.4742s2.8242-1.6989 3.7399-3.0693 1.4044-2.9815 1.4044-4.6297c0-2.2101-0.878-4.3298-2.4408-5.8926s-3.6824-2.4408-5.8925-2.4408zm4.9833 8.4008v0.0225c-0.0053 0.0736-0.0193 0.1463-0.0416 0.2167-0.0167 0.0291-0.0334 0.0533-0.05 0.0833-0.0287 0.0582-0.0652 0.1124-0.1084 0.1608-0.0281 0.0261-0.0588 0.0493-0.0916 0.0692l-2.1334 3.16c-0.1422 0.1886-0.3463 0.321-0.5764 0.3741-0.2301 0.053-0.4715 0.0232-0.6819-0.0841-0.1017-0.0394-0.1933-0.1011-0.2679-0.1807-0.0746-0.0795-0.1304-0.1749-0.1632-0.2789s-0.0418-0.2141-0.0263-0.3221c0.0155-0.1079 0.055-0.211 0.1157-0.3016l1.45-2.1525h-6.575c-0.22102 0-0.43302-0.0878-0.5893-0.2441s-0.24404-0.3683-0.24404-0.5893c0-0.22098 0.08776-0.43296 0.24404-0.58924s0.36828-0.24406 0.5893-0.24406h6.575l-1.45-2.1517c-0.0605-0.09066-0.0999-0.19376-0.1153-0.30169s-0.0065-0.21794 0.0263-0.32191 0.0886-0.19926 0.1631-0.27883 0.166-0.1414 0.2676-0.18092c0.2105-0.10685 0.4518-0.13634 0.6818-0.08333 0.23 0.053 0.434 0.18516 0.5765 0.37335l2.1167 3.135c0.0136 0.01067 0.025 0.02399 0.0333 0.03916 0.0719 0.06084 0.1337 0.13255 0.1834 0.21251 0.0546 0.09525 0.0859 0.20201 0.0916 0.31166v0.01332c0.0032 0.00376 0.0056 0.00816 7e-3 0.0129 0.0015 0.00473 0.0019 0.00971 0.0014 0.01462 0 0.01333 0.0083 0.02499 0.0083 0.03832 0 0.01331-0.0083 0.02581-0.0083 0.03831 0.0011 0.0105-0.0019 0.0209-0.0084 0.0292z"
                          clipRule="evenodd"
                          fill="#fff"
                          fillRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </button> */}

                  {partnerNameActual === 'gympass' ? null :

                  <button
                    id="add-30135749771346-sitedeal"
                    onClick={() =>
                      MembershipAdd(yearlyMembership, 20)
                    }
                    target="_self"
                    title="Choose Plan"
                    aria-label="Choose the Yearly Plan"
                    className="credera-snippet primary-button primary-button-with-icon"
                  >
                    {" "}
                    <span className="button-content buy-button" tabIndex="0">
                      {" "}
                      <span>Choose Plan</span>{" "}
                      <svg
                        fill="none"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {" "}
                        <path
                          d="m10 20c-1.9778 0-3.9112-0.5865-5.5557-1.6853s-2.9262-2.6606-3.6831-4.4879c-0.75688-1.8272-0.9549-3.8379-0.56905-5.7777s1.3383-3.7216 2.7368-5.1202c1.3985-1.3985 3.1803-2.3509 5.1201-2.7368s3.9504-0.18782 5.7777 0.56905c1.8273 0.75688 3.3891 2.0386 4.4879 3.6831 1.0988 1.6445 1.6853 3.5779 1.6853 5.5557 0 2.6522-1.0536 5.1957-2.929 7.0711-1.8753 1.8753-4.4188 2.9289-7.071 2.9289zm0-18.333c-1.6482 0-3.2593 0.48874-4.6297 1.4044s-2.4385 2.2172-3.0692 3.7399-0.79579 3.1982-0.47424 4.8147c0.32154 1.6165 1.1152 3.1014 2.2807 4.2669 1.1654 1.1654 2.6502 1.9591 4.2668 2.2806 1.6165 0.3216 3.2921 0.1565 4.8148-0.4742s2.8242-1.6989 3.7399-3.0693 1.4044-2.9815 1.4044-4.6297c0-2.2101-0.878-4.3298-2.4408-5.8926s-3.6824-2.4408-5.8925-2.4408zm4.9833 8.4008v0.0225c-0.0053 0.0736-0.0193 0.1463-0.0416 0.2167-0.0167 0.0291-0.0334 0.0533-0.05 0.0833-0.0287 0.0582-0.0652 0.1124-0.1084 0.1608-0.0281 0.0261-0.0588 0.0493-0.0916 0.0692l-2.1334 3.16c-0.1422 0.1886-0.3463 0.321-0.5764 0.3741-0.2301 0.053-0.4715 0.0232-0.6819-0.0841-0.1017-0.0394-0.1933-0.1011-0.2679-0.1807-0.0746-0.0795-0.1304-0.1749-0.1632-0.2789s-0.0418-0.2141-0.0263-0.3221c0.0155-0.1079 0.055-0.211 0.1157-0.3016l1.45-2.1525h-6.575c-0.22102 0-0.43302-0.0878-0.5893-0.2441s-0.24404-0.3683-0.24404-0.5893c0-0.22098 0.08776-0.43296 0.24404-0.58924s0.36828-0.24406 0.5893-0.24406h6.575l-1.45-2.1517c-0.0605-0.09066-0.0999-0.19376-0.1153-0.30169s-0.0065-0.21794 0.0263-0.32191 0.0886-0.19926 0.1631-0.27883 0.166-0.1414 0.2676-0.18092c0.2105-0.10685 0.4518-0.13634 0.6818-0.08333 0.23 0.053 0.434 0.18516 0.5765 0.37335l2.1167 3.135c0.0136 0.01067 0.025 0.02399 0.0333 0.03916 0.0719 0.06084 0.1337 0.13255 0.1834 0.21251 0.0546 0.09525 0.0859 0.20201 0.0916 0.31166v0.01332c0.0032 0.00376 0.0056 0.00816 7e-3 0.0129 0.0015 0.00473 0.0019 0.00971 0.0014 0.01462 0 0.01333 0.0083 0.02499 0.0083 0.03832 0 0.01331-0.0083 0.02581-0.0083 0.03831 0.0011 0.0105-0.0019 0.0209-0.0084 0.0292z"
                          clipRule="evenodd"
                          fill="#fff"
                          fillRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </button>
}
                </div>
              </div>
              <div 
                className="membership-card"
                id="card-32930539503698"
                tabIndex="0"
                data-vid="32930539503698"
                data-price="69999"
                data-term="Two Years"
              >
                <div className="card-header">
                  <h3 className="card-title">Two-year</h3>
                  <div className="tag best-value">
                    Best Value
                  </div>
                  <div className="card-price">
                  {bcbsCheck ? "$26.25" : "$29.16" }<span>/mo</span>
                  </div>
                  <p className="card-billing-period">Billed as {bcbsCheck ? "$629" : "$699" } every two years</p>
                </div>
                <div className="card-body">
                  <div className="card-content">
                  </div>
                </div>
                <div className="card-footer">
                  {/* <button
                    id="add-32930539503698"
                    onClick={() =>
                      MembershipAdd(twoYearMembership)
                    }
                    target="_self"
                    title="Choose Plan"
                    aria-label="Choose the Two-year Plan"
                    className="credera-snippet primary-button primary-button-with-icon"
                  >
                    {" "}
                    <span className="button-content buy-button" tabIndex="0">
                      {" "}
                      <span>Plan + Free iPad</span>{" "}
                      <svg
                        fill="none"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {" "}
                        <path
                          d="m10 20c-1.9778 0-3.9112-0.5865-5.5557-1.6853s-2.9262-2.6606-3.6831-4.4879c-0.75688-1.8272-0.9549-3.8379-0.56905-5.7777s1.3383-3.7216 2.7368-5.1202c1.3985-1.3985 3.1803-2.3509 5.1201-2.7368s3.9504-0.18782 5.7777 0.56905c1.8273 0.75688 3.3891 2.0386 4.4879 3.6831 1.0988 1.6445 1.6853 3.5779 1.6853 5.5557 0 2.6522-1.0536 5.1957-2.929 7.0711-1.8753 1.8753-4.4188 2.9289-7.071 2.9289zm0-18.333c-1.6482 0-3.2593 0.48874-4.6297 1.4044s-2.4385 2.2172-3.0692 3.7399-0.79579 3.1982-0.47424 4.8147c0.32154 1.6165 1.1152 3.1014 2.2807 4.2669 1.1654 1.1654 2.6502 1.9591 4.2668 2.2806 1.6165 0.3216 3.2921 0.1565 4.8148-0.4742s2.8242-1.6989 3.7399-3.0693 1.4044-2.9815 1.4044-4.6297c0-2.2101-0.878-4.3298-2.4408-5.8926s-3.6824-2.4408-5.8925-2.4408zm4.9833 8.4008v0.0225c-0.0053 0.0736-0.0193 0.1463-0.0416 0.2167-0.0167 0.0291-0.0334 0.0533-0.05 0.0833-0.0287 0.0582-0.0652 0.1124-0.1084 0.1608-0.0281 0.0261-0.0588 0.0493-0.0916 0.0692l-2.1334 3.16c-0.1422 0.1886-0.3463 0.321-0.5764 0.3741-0.2301 0.053-0.4715 0.0232-0.6819-0.0841-0.1017-0.0394-0.1933-0.1011-0.2679-0.1807-0.0746-0.0795-0.1304-0.1749-0.1632-0.2789s-0.0418-0.2141-0.0263-0.3221c0.0155-0.1079 0.055-0.211 0.1157-0.3016l1.45-2.1525h-6.575c-0.22102 0-0.43302-0.0878-0.5893-0.2441s-0.24404-0.3683-0.24404-0.5893c0-0.22098 0.08776-0.43296 0.24404-0.58924s0.36828-0.24406 0.5893-0.24406h6.575l-1.45-2.1517c-0.0605-0.09066-0.0999-0.19376-0.1153-0.30169s-0.0065-0.21794 0.0263-0.32191 0.0886-0.19926 0.1631-0.27883 0.166-0.1414 0.2676-0.18092c0.2105-0.10685 0.4518-0.13634 0.6818-0.08333 0.23 0.053 0.434 0.18516 0.5765 0.37335l2.1167 3.135c0.0136 0.01067 0.025 0.02399 0.0333 0.03916 0.0719 0.06084 0.1337 0.13255 0.1834 0.21251 0.0546 0.09525 0.0859 0.20201 0.0916 0.31166v0.01332c0.0032 0.00376 0.0056 0.00816 7e-3 0.0129 0.0015 0.00473 0.0019 0.00971 0.0014 0.01462 0 0.01333 0.0083 0.02499 0.0083 0.03832 0 0.01331-0.0083 0.02581-0.0083 0.03831 0.0011 0.0105-0.0019 0.0209-0.0084 0.0292z"
                          clipRule="evenodd"
                          fill="#fff"
                          fillRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </button> */}

                  {partnerNameActual === 'gympass' ? null :
                  <button
                    id="add-32930539503698-sitedeal"
                    onClick={() =>
                      MembershipAdd(twoYearMembership, 20)
                    }
                    target="_self"
                    title="Choose Plan"
                    aria-label="Choose the Two-year Plan"
                    className="credera-snippet primary-button primary-button-with-icon"
                  >
                    {" "}
                    <span className="button-content buy-button" tabIndex="0">
                      {" "}
                      <span>Choose Plan</span>{" "}
                      <svg
                        fill="none"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {" "}
                        <path
                          d="m10 20c-1.9778 0-3.9112-0.5865-5.5557-1.6853s-2.9262-2.6606-3.6831-4.4879c-0.75688-1.8272-0.9549-3.8379-0.56905-5.7777s1.3383-3.7216 2.7368-5.1202c1.3985-1.3985 3.1803-2.3509 5.1201-2.7368s3.9504-0.18782 5.7777 0.56905c1.8273 0.75688 3.3891 2.0386 4.4879 3.6831 1.0988 1.6445 1.6853 3.5779 1.6853 5.5557 0 2.6522-1.0536 5.1957-2.929 7.0711-1.8753 1.8753-4.4188 2.9289-7.071 2.9289zm0-18.333c-1.6482 0-3.2593 0.48874-4.6297 1.4044s-2.4385 2.2172-3.0692 3.7399-0.79579 3.1982-0.47424 4.8147c0.32154 1.6165 1.1152 3.1014 2.2807 4.2669 1.1654 1.1654 2.6502 1.9591 4.2668 2.2806 1.6165 0.3216 3.2921 0.1565 4.8148-0.4742s2.8242-1.6989 3.7399-3.0693 1.4044-2.9815 1.4044-4.6297c0-2.2101-0.878-4.3298-2.4408-5.8926s-3.6824-2.4408-5.8925-2.4408zm4.9833 8.4008v0.0225c-0.0053 0.0736-0.0193 0.1463-0.0416 0.2167-0.0167 0.0291-0.0334 0.0533-0.05 0.0833-0.0287 0.0582-0.0652 0.1124-0.1084 0.1608-0.0281 0.0261-0.0588 0.0493-0.0916 0.0692l-2.1334 3.16c-0.1422 0.1886-0.3463 0.321-0.5764 0.3741-0.2301 0.053-0.4715 0.0232-0.6819-0.0841-0.1017-0.0394-0.1933-0.1011-0.2679-0.1807-0.0746-0.0795-0.1304-0.1749-0.1632-0.2789s-0.0418-0.2141-0.0263-0.3221c0.0155-0.1079 0.055-0.211 0.1157-0.3016l1.45-2.1525h-6.575c-0.22102 0-0.43302-0.0878-0.5893-0.2441s-0.24404-0.3683-0.24404-0.5893c0-0.22098 0.08776-0.43296 0.24404-0.58924s0.36828-0.24406 0.5893-0.24406h6.575l-1.45-2.1517c-0.0605-0.09066-0.0999-0.19376-0.1153-0.30169s-0.0065-0.21794 0.0263-0.32191 0.0886-0.19926 0.1631-0.27883 0.166-0.1414 0.2676-0.18092c0.2105-0.10685 0.4518-0.13634 0.6818-0.08333 0.23 0.053 0.434 0.18516 0.5765 0.37335l2.1167 3.135c0.0136 0.01067 0.025 0.02399 0.0333 0.03916 0.0719 0.06084 0.1337 0.13255 0.1834 0.21251 0.0546 0.09525 0.0859 0.20201 0.0916 0.31166v0.01332c0.0032 0.00376 0.0056 0.00816 7e-3 0.0129 0.0015 0.00473 0.0019 0.00971 0.0014 0.01462 0 0.01333 0.0083 0.02499 0.0083 0.03832 0 0.01331-0.0083 0.02581-0.0083 0.03831 0.0011 0.0105-0.0019 0.0209-0.0084 0.0292z"
                          clipRule="evenodd"
                          fill="#fff"
                          fillRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </button>
}
                </div>
              </div>
          </div>
          }
          </div>
        </div>
      </div>
      :
      null
      }
      {products
        ? products.map((product, index) => (
            <Product
              key={index}
              product={product}
              index={index}
              addToCart={addToCart}
            />
          ))
        : <div>LOADING....</div>}

{partnerNameActual === 'gympass' ? "" :


fitPass
  ? <Product
      key='fitpass'
      product={fitPass}
      index={productCount}
      addToCart={addToCart} />
  : null

}



    </div>
  );
}
