import React from "react";

export default function Banner() {
  return (
    <div className="banner">
      <div className="logo">
        <svg
          width="150"
          height="50"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          viewBox="0 0 190.78 33.12"
        >
          {" "}
          <g>
            {" "}
            <g>
              {" "}
              <circle
                fill="#002a3a"
                cx="16.56"
                cy="16.56"
                r="16.55"
              ></circle>{" "}
              <path
                fill="#0083c1"
                d="M12.52,5.68c-5.59,2.08-8.68,7.99-7.2,13.75c0.1,0.39,0.22,0.78,0.37,1.17c0.13,0.35,0.27,0.68,0.42,0.99    c2.61,5.44,8.84,7.95,14.5,5.85c3.64-1.35,6.34-4.38,7.24-8.12c0.14-0.56,0.08-1.17-0.25-1.64c-0.51-0.74-1.42-1.01-2.22-0.71    l-1.08,0.4c-0.56,0.21-0.98,0.66-1.16,1.24c-0.63,2.05-2.15,3.65-4.18,4.4c-1.77,0.66-3.69,0.57-5.4-0.26    c-1.71-0.83-2.97-2.27-3.55-4.07c-1.05-3.22,0.45-6.78,3.49-8.29c0.22-0.11,0.44-0.21,0.67-0.29c2.76-1.02,5.84-0.17,7.68,2.03    l-9.37,3.48c-0.43,0.16-0.82,0.42-1.09,0.79c-0.55,0.75-0.58,1.71-0.13,2.48c0.55,0.94,1.72,1.33,2.78,0.93l12.99-4.83    c0.58-0.22,0.91-0.84,0.75-1.44v0c-0.09-0.34-0.2-0.68-0.33-1.03C25.21,6.52,18.52,3.45,12.52,5.68z"
              ></path>
            </g>{" "}
            <g>
              {" "}
              <g>
                {" "}
                <path
                  fill="#fff"
                  d="M184.17,3.62h2.69v0.42h-1.08v2.56h-0.52V4.05h-1.08V3.62z M190.28,6.61l-0.01-2.09l-1.12,1.74h-0.24     l-1.13-1.7v2.05h-0.5V3.62h0.42l1.33,2.04l1.3-2.04h0.42l0.01,2.98H190.28z"
                ></path>
              </g>{" "}
              <g>
                {" "}
                <path
                  fill="#fff"
                  d="M55.13,21.57h2.44c0.21-2.37-0.04-4.43-0.75-6.18c-0.71-1.75-1.8-3.11-3.28-4.08     c-1.48-0.97-3.28-1.45-5.39-1.45c-1.99,0-3.75,0.44-5.28,1.31c-1.53,0.88-2.73,2.1-3.6,3.69c-0.87,1.58-1.31,3.44-1.31,5.58     c0,1.96,0.44,3.69,1.33,5.21c0.89,1.52,2.12,2.71,3.69,3.57c1.57,0.86,3.39,1.3,5.46,1.3c1.94,0,3.73-0.5,5.36-1.49     c1.64-0.99,2.83-2.38,3.59-4.17l-5.07-1.41c-0.37,0.8-0.91,1.41-1.62,1.83c-0.71,0.42-1.56,0.63-2.56,0.63     c-1.58,0-2.78-0.5-3.59-1.5c-0.6-0.73-0.97-1.68-1.13-2.85h9.12H55.13z M48.38,14.14c1.48,0,2.55,0.44,3.2,1.31     c0.43,0.57,0.7,1.41,0.85,2.46h-8.84c0.2-0.9,0.52-1.66,0.98-2.24C45.38,14.65,46.65,14.14,48.38,14.14z"
                ></path>{" "}
                <path
                  fill="#fff"
                  d="M70.65,30.52c-2.09,0-3.88-0.45-5.37-1.36s-2.64-2.14-3.43-3.7s-1.19-3.32-1.19-5.27     c0-1.98,0.41-3.75,1.24-5.31c0.83-1.56,1.99-2.78,3.5-3.68s3.28-1.34,5.34-1.34c2.38,0,4.37,0.58,5.98,1.75s2.64,2.76,3.09,4.77     l-5.07,1.3c-0.3-1.01-0.82-1.81-1.56-2.37c-0.74-0.57-1.58-0.85-2.51-0.85c-1.07,0-1.95,0.25-2.63,0.75     c-0.68,0.5-1.19,1.18-1.51,2.05c-0.32,0.86-0.49,1.84-0.49,2.93c0,1.7,0.39,3.08,1.17,4.14s1.93,1.59,3.46,1.59     c1.14,0,2.01-0.25,2.61-0.76c0.6-0.51,1.04-1.23,1.34-2.17l5.19,1.05c-0.57,2.08-1.65,3.68-3.25,4.8S72.99,30.52,70.65,30.52z"
                ></path>{" "}
                <path
                  fill="#fff"
                  d="M102.33,17.44c-0.05-0.71-0.19-1.5-0.43-2.36c-0.24-0.86-0.63-1.69-1.18-2.49c-0.55-0.8-1.33-1.46-2.32-1.98     s-2.28-0.78-3.86-0.78c-1.98,0-3.62,0.42-4.92,1.25C89,11.47,88.47,11.93,88,12.44V3.88h-4.51v26.09h5.15v-9.93     c0-1.22,0.14-2.2,0.42-2.94c0.28-0.74,0.64-1.3,1.07-1.68c0.43-0.38,0.9-0.64,1.4-0.77c0.5-0.13,0.96-0.2,1.4-0.2     c1.08,0,1.92,0.24,2.51,0.71c0.59,0.47,1.02,1.06,1.28,1.76c0.26,0.7,0.42,1.4,0.47,2.09c0.05,0.69,0.07,1.27,0.07,1.71v9.24     h5.15V19C102.4,18.67,102.38,18.15,102.33,17.44z"
                ></path>{" "}
                <path
                  fill="#fff"
                  d="M123.3,21.57h2.44c0.21-2.37-0.04-4.43-0.75-6.18c-0.71-1.75-1.8-3.11-3.28-4.08     c-1.48-0.97-3.28-1.45-5.39-1.45c-1.99,0-3.75,0.44-5.28,1.31c-1.53,0.88-2.73,2.1-3.6,3.69c-0.87,1.58-1.31,3.44-1.31,5.58     c0,1.96,0.44,3.69,1.33,5.21c0.89,1.52,2.12,2.71,3.69,3.57c1.57,0.86,3.39,1.3,5.46,1.3c1.94,0,3.73-0.5,5.36-1.49     c1.64-0.99,2.83-2.38,3.59-4.17l-5.07-1.41c-0.37,0.8-0.91,1.41-1.62,1.83c-0.71,0.42-1.56,0.63-2.56,0.63     c-1.58,0-2.78-0.5-3.59-1.5c-0.6-0.73-0.97-1.68-1.13-2.85h9.12H123.3z M116.54,14.14c1.48,0,2.55,0.44,3.2,1.31     c0.43,0.57,0.7,1.41,0.85,2.46h-8.84c0.2-0.9,0.52-1.66,0.98-2.24C113.54,14.65,114.81,14.14,116.54,14.14z"
                ></path>{" "}
                <path
                  fill="#fff"
                  d="M130.31,29.98V3.34h5.07v26.64H130.31z"
                ></path>{" "}
                <path
                  fill="#fff"
                  d="M150.05,30.52c-2.03,0-3.81-0.44-5.33-1.32s-2.72-2.1-3.57-3.65c-0.85-1.55-1.28-3.34-1.28-5.35     c0-2.04,0.44-3.84,1.31-5.39c0.87-1.55,2.07-2.76,3.6-3.63c1.53-0.87,3.29-1.3,5.28-1.3c2.03,0,3.81,0.44,5.34,1.32     s2.73,2.1,3.59,3.65c0.86,1.55,1.29,3.34,1.29,5.35c0,2.03-0.43,3.82-1.3,5.37c-0.86,1.55-2.06,2.77-3.6,3.64     C153.84,30.08,152.06,30.52,150.05,30.52z M150.05,25.92c1.63,0,2.85-0.53,3.65-1.6c0.8-1.07,1.2-2.44,1.2-4.12     c0-1.74-0.41-3.13-1.22-4.17s-2.02-1.56-3.63-1.56c-1.11,0-2.01,0.24-2.72,0.72c-0.71,0.48-1.23,1.15-1.58,2.01     s-0.51,1.85-0.51,2.99c0,1.75,0.41,3.14,1.22,4.18C147.27,25.4,148.47,25.92,150.05,25.92z"
                ></path>{" "}
                <path
                  fill="#fff"
                  d="M183.18,17.44c-0.05-0.71-0.19-1.5-0.43-2.36c-0.24-0.86-0.63-1.69-1.18-2.49c-0.55-0.8-1.33-1.46-2.32-1.98     s-2.28-0.78-3.86-0.78c-1.98,0-3.62,0.42-4.92,1.25c-0.61,0.39-1.15,0.85-1.61,1.36v-2.03h-4.51v19.57h5.15v-9.93     c0-1.22,0.14-2.2,0.42-2.94c0.28-0.74,0.64-1.3,1.07-1.68c0.43-0.38,0.9-0.64,1.4-0.77c0.5-0.13,0.96-0.2,1.4-0.2     c1.08,0,1.92,0.24,2.51,0.71c0.59,0.47,1.02,1.06,1.28,1.76c0.26,0.7,0.42,1.4,0.47,2.09c0.05,0.69,0.07,1.27,0.07,1.71v9.24     h5.15V19C183.25,18.67,183.23,18.15,183.18,17.44z"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
      {/* {partner.toLowerCase() === "gympass" ? (
        <div>
          <span>
            **Free membership with purchase of Echelon Connected Product
          </span>
        </div>
      ) : null} */}
    </div>
  );
}
