import React from 'react';

export default function Footer() {

    return (
        <section className='footer-container'>
            <div className='footer'>
                <div className='inner-footer'>
                    <div className='footer-block spacer'></div>
                    <div className='footer-block'>
                        <h4>Contact Us</h4>
                        <a href="tel:423-455-8989">Call Us: 423-455-8989</a>
                    </div>
                    <div className='footer-block'>
                        <h4>Connect With Us</h4>
                        <ul className="credera-snippet social-icons">
                            <li> 
                                <a href="https://www.instagram.com/echelon.fit/" aria-label="Visit Echelon on Instagram in new tab" rel="noopener noreferrer" target="_blank"> 
                                    <svg aria-hidden="true" className="instagram-icon" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"> 
                                        <title>Instagram Icon</title> 
                                        <path className="fill" d="m17 2h-10c-2.7614 0-5 2.2386-5 5v10c0 2.7614 2.2386 5 5 5h10c2.7614 0 5-2.2386 5-5v-10c0-2.7614-2.2386-5-5-5z" fill="#fff"></path>
                                        <path className="stroke" d="m16 11.37c0.1234 0.8322-0.0187 1.6822-0.4062 2.429s-1.0006 1.3524-1.7522 1.7307c-0.7515 0.3782-1.6031 0.5099-2.4338 0.3762-0.8307-0.1336-1.598-0.5258-2.193-1.1207-0.59493-0.595-0.98711-1.3623-1.1208-2.193-0.13366-0.8307-2e-3 -1.6823 0.37626-2.4338 0.37826-0.75155 0.98386-1.3647 1.7307-1.7522 0.7468-0.3875 1.5968-0.52966 2.429-0.40624 0.8489 0.12588 1.6349 0.52146 2.2417 1.1283 0.6068 0.60685 1.0024 1.3928 1.1283 2.2417z" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="#002A3A"></path>
                                        <path className="stroke" d="m17.5 6.5h0.01" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="#002A3A"></path>
                                    </svg>
                                </a>
                            </li>
                            <li> 
                                <a href="https://www.facebook.com/Echelon.Fit/" aria-label="Visit Echelon on Facebook in new tab" rel="noopener noreferrer" target="_blank"> 
                                    <svg aria-hidden="true" className="facebook-icon" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"> 
                                        <title>Facebook Icon</title> 
                                        <path className="fill" d="M18 2H15C13.6739 2 12.4021 2.52678 11.4645 3.46447C10.5268 4.40215 10 5.67392 10 7V10H7V14H10V22H14V14H17L18 10H14V7C14 6.73478 14.1054 6.48043 14.2929 6.29289C14.4804 6.10536 14.7348 6 15 6H18V2Z" fill="#fff"></path>
                                    </svg>
                                </a>
                            </li>
                            <li> 
                                <a href="https://www.youtube.com/channel/UCM472fZV9xRgR0hyhy786Mg" aria-label="Visit Echelon on Youtube in new tab" rel="noopener noreferrer" target="_blank"> 
                                    <svg aria-hidden="true" className="youtube-icon" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"> 
                                        <title>Youtube Icon</title> 
                                        <path className="fill" d="m30.053 8.56c-0.1584-0.63279-0.481-1.2126-0.9351-1.6808-0.4542-0.46822-1.0239-0.8083-1.6516-0.98588-2.2933-0.56-11.467-0.56-11.467-0.56s-9.1734 0-11.467 0.61334c-0.62767 0.17758-1.1974 0.51765-1.6515 0.98587-0.45418 0.46822-0.77675 1.048-0.93513 1.6808-0.41972 2.3274-0.62502 4.6884-0.61334 7.0533-0.01496 2.3828 0.19036 4.7618 0.61334 7.1067 0.17461 0.6131 0.50441 1.1709 0.95752 1.6193 0.45312 0.4485 1.0142 0.7725 1.6291 0.9407 2.2933 0.6133 11.467 0.6133 11.467 0.6133s9.1733 0 11.467-0.6133c0.6277-0.1776 1.1974-0.5176 1.6516-0.9859 0.4541-0.4682 0.7767-1.048 0.9351-1.6808 0.4165-2.3098 0.6218-4.6528 0.6133-7 0.015-2.3827-0.1903-4.7617-0.6133-7.1066z" fill="#fff"></path>
                                        <path className="fill-cut" d="m13 20.027 7.6667-4.36-7.6667-4.36v8.72z" fill="#002A3A"></path>
                                    </svg>
                                </a>
                            </li>
                            <li> 
                                <a href="https://twitter.com/Echelon_Fit" aria-label="Visit Echelon on Twitter in new tab" rel="noopener noreferrer" target="_blank">
                                    <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"> 
                                        <title>Twitter Icon</title> 
                                        <path className="fill" d="m23 3c-0.9576 0.67548-2.0179 1.1921-3.14 1.53-0.6023-0.69249-1.4027-1.1833-2.293-1.4061-0.8903-0.22276-1.8275-0.16673-2.6849 0.16053s-1.5937 0.90995-2.1091 1.6693c-0.5155 0.75932-0.7853 1.6586-0.773 2.5763v1c-1.7574 0.04557-3.4987-0.34418-5.069-1.1346-1.5703-0.79037-2.9207-1.9568-3.931-3.3954 0 0-4 9 5 13-2.0595 1.398-4.5128 2.0989-7 2 9 5 20 0 20-11.5-9e-4 -0.27854-0.0277-0.5564-0.08-0.82999 1.0206-1.0065 1.7408-2.2773 2.08-3.67z" fill="#fff"></path>
                                    </svg>
                                </a>
                            </li>
                            <li> 
                                <a href="https://www.linkedin.com/company/echelonfit/" aria-label="Visit Echelon on LinkedIn in new tab" rel="noopener noreferrer" target="_blank">
                                    <svg aria-hidden="true" className="youtube-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <g id="surface1">
                                            <path d="M 21.828125 0 L 2.171875 0 C 0.972656 0 0 0.972656 0 2.171875 L 0 21.828125 C 0 23.027344 0.972656 24 2.171875 24 L 21.828125 24 C 23.027344 24 24 23.027344 24 21.828125 L 24 2.171875 C 24 0.972656 23.027344 0 21.828125 0 Z M 7.425781 20.722656 C 7.425781 21.070312 7.144531 21.355469 6.792969 21.355469 L 4.105469 21.355469 C 3.757812 21.355469 3.472656 21.070312 3.472656 20.722656 L 3.472656 9.449219 C 3.472656 9.101562 3.757812 8.816406 4.105469 8.816406 L 6.792969 8.816406 C 7.144531 8.816406 7.425781 9.101562 7.425781 9.449219 Z M 5.449219 7.753906 C 4.039062 7.753906 2.894531 6.609375 2.894531 5.199219 C 2.894531 3.789062 4.039062 2.644531 5.449219 2.644531 C 6.859375 2.644531 8.003906 3.789062 8.003906 5.199219 C 8.003906 6.609375 6.859375 7.753906 5.449219 7.753906 Z M 21.480469 20.773438 C 21.480469 21.09375 21.222656 21.355469 20.898438 21.355469 L 18.015625 21.355469 C 17.695312 21.355469 17.433594 21.09375 17.433594 20.773438 L 17.433594 15.484375 C 17.433594 14.695312 17.664062 12.027344 15.371094 12.027344 C 13.59375 12.027344 13.234375 13.855469 13.160156 14.675781 L 13.160156 20.773438 C 13.160156 21.09375 12.898438 21.355469 12.578125 21.355469 L 9.789062 21.355469 C 9.46875 21.355469 9.207031 21.09375 9.207031 20.773438 L 9.207031 9.398438 C 9.207031 9.078125 9.46875 8.816406 9.789062 8.816406 L 12.578125 8.816406 C 12.898438 8.816406 13.160156 9.078125 13.160156 9.398438 L 13.160156 10.382812 C 13.820312 9.390625 14.800781 8.628906 16.886719 8.628906 C 21.507812 8.628906 21.480469 12.945312 21.480469 15.316406 Z M 21.480469 20.773438" fill="#fff"></path>
                                        </g>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className='footer-block spacer'></div>
                </div>
            </div>
            <div className='sub-footer'>
                <div className="container">
                    <div>
                        <p className="details">Discount applies to standard MSRP and is limited to the equipment listed above. Partner offers for equipment can be combined with New Customer Discount, but cannot be combined with any other equipment discounts, including but not limited to Echelonfit.com Special Offers, Open Box, or Outlet Items. While supplies last.</p>
                        <p className="details">
                        *Promotional APR Based on Credit: Offer available on qualifying purchases of select products charged to a Citizens Pay Line of Credit account. Minimum purchase: $300.00. Promotional offers are based on creditworthiness and range from [0% -19.99%] APR from the date an eligible purchase is posted to your account until paid in full. Limited time offer. If financing your Echelon Premier membership, the total cost of the membership will also be divided into equal monthly payments for the duration of the repayment term. Other transactions charged to your account affect overall minimum payment amount. Regular account terms apply to non-promo purchases. Standard Purchase APR is 29.99% for non-promotional purchases. Account must be in good standing. Subject to credit approval. <strong><a className="footerLink" href="https://www.citizensbank.com/disclosures/GenericAPR-CT-CitizensPay.aspx"> Click here</a></strong> for important information about Rates and Fees and the Citizens Pay Line of Credit Agreement. Citizens Pay Line of Credit Account offered by Citizens Bank, N.A.  
                        </p>
                    </div>
                    <div className="links-list-container">
                        <ul className="links-list">
                            <li className="" id="footer-navi-2">
                                <a className="text-white underline text-small" href="https://echelonfit.com/pages/privacy-policy">Privacy Policy</a>
                            </li>
                            <li className="" id="footer-navi-2">
                                <a className="" href="https://echeloncommercial.com/terms-and-conditions">Terms and Conditions</a>
                            </li>
                            <li className="" id="footer-navi-2">
                                <a className="" href="https://echelonfit.com/pages/accessibility-statement">Accessibility</a>
                            </li>
                        </ul>
                    </div>
                    <div className="subfooter-bottom">
                        <div className="subfooter-text"></div>
                        <p className="copyright pb-20">©{(new Date().getFullYear())} Echelon Fitness Multimedia LLC. All rights reserved. Patent pending.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}
