import React from 'react';
// import { Link } from 'react-router-dom';
import Footer from './Footer'
import Banner from './Banner'

export default function HomePage({partnerData}) {
    return partnerData ? (
        <section>
            <Banner />
            <div className='container' style={{maxWidth: '1020px', padding: '16px'}}>
                <h1>Echelon Partners Site</h1>
                <h2 style={{ fontSize: '4rem', margin: '32px 0', color: '#0083c1' }}>403</h2>
                <p style={{ fontSize: '5rem', margin: '32px 0', lineHeight: '78px' }}>OOPS! You don't have access to see this.</p>
                <p style={{ fontSize: '2rem' }}>Please check your partner link and try again.</p>
            </div>
            {/* {partnerData ? 
            <div>
               <h3>Partner Links</h3>
                <ul>
                    {partnerData.map(p => {
                        let actualRoute = `/affiliate/${p.slug}`

                        if (p.slug === 'ebg' || p.slug === 'benefithub') {
                            actualRoute = `/echelon-marketplace/${p.slug}`;
                        }
                        
                        return <li key={p.slug}>
                            <Link to={actualRoute}>{p.slug}</Link>
                        </li>
                    })}
                </ul>
            </div>
                :
                'Loading....'
            } */}
            <Footer />
        </section>
    ) : null
}