import React from 'react';
import Banner from './Banner';
import Footer from './Footer';

export default function FourOFour() {
    return (
        <section>
            <Banner />
            <div className='container' style={{maxWidth: '1020px', padding: '16px'}}>
                <h1>Echelon Partners Site</h1>
                <h2 style={{ fontSize: '4rem', margin: '32px 0', color: '#0083c1' }}>404</h2>
                <p style={{ fontSize: '5rem', margin: '32px 0', lineHeight: '78px' }}>OOPS! You've found a page that doesn't exist.</p>
                <p style={{ fontSize: '2rem' }}>Please check your partner link and try again.</p>
            </div>
            <Footer />
        </section>
    )      

}