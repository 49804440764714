import { request } from "graphql-request";
import Client from "shopify-buy";

export const fetchPages = async () => {
  const { partnerPages } = await request(
    "https://api-us-east-1.graphcms.com/v2/cktu7slh8096f01xzhj1phdic/master",
    `
      { 
        partnerPages {
            freeShipping
            header
            slug
            urlPrefix
            partnerHeroImage {
            url
            title
            }
            partnerLogo {
            url
            }
        }
      }
    `
  );

  return partnerPages;
};

export const fetchSalesBanner = async () => {
  const { saleBanners } = await request(
    "https://api-us-east-1.graphcms.com/v2/cktu7slh8096f01xzhj1phdic/master",
    `
    {
      saleBanners {
        salesBannerCopy
      }
    }
    `
  );

  return saleBanners;
}

const collectionId = "gid://shopify/Collection/167055622226";

// Initializing a client to return content in the store's primary language
export const client = Client.buildClient({
  domain: "echelon-store.myshopify.com",
  storefrontAccessToken: "9c0ac27b14b96019cd5c15eb7ed44402",
});

export const getConnectedProducts = async () => {
  return await client.collection
    .fetchWithProducts(collectionId, { productsFirst: 20 })
    .then((collection) => {
      // return the products in the collection
      return collection.products;
    });
};

export const getFitPass = async () => {
  return await client.product.fetchByHandle('fitpass-unlimited-monthly-classes', { productsFirst: 1 })
  .then((fitPass) => {
    return fitPass;
  })
}

export const createCheckout = async () => {
  return await client.checkout.create().then((checkout) => {
    return checkout;
  });
};

export const fetchCheckoutCookie = async (checkoutId) => {
  return await client.checkout.fetch(checkoutId).then((checkout) => {
    return checkout;
  });
}

export const checkForFreeShipping = async (checkoutId) => {
  return await client.checkout.fetch(checkoutId).then((checkout) => {
    return checkout.lineItems.filter(item => item.id === 'gid://shopify/CheckoutLineItem/326469018911540?checkout=5205b6d6a08c9fd523761b6131129d14').length > 0 ? true : false;
  });
}

export const addCheckoutAttribut = async (checkoutId, input) => {
  return await client.checkout.updateAttributes(checkoutId, input).then((checkout) => {
    return checkout;
  })
  .catch(error => error);
}

export const checkCart = async (collectionId) => {
  return await client.checkout.fetch(collectionId).then((response) => response);
};

export const updateCart = async (lineItem, checkoutId) => {
  return await client.checkout
    .addLineItems(checkoutId, lineItem)
    .then((checkout) => {
      return checkout
    });
};

export const clearCart = async (checkoutId, lineItemIdsToRemove) => {
  return client.checkout
    .removeLineItems(checkoutId, lineItemIdsToRemove)
    .then((checkout) => checkout);
}

export const discountAdd = async (checkoutId, discountCode) => {
  return await client.checkout.addDiscount(checkoutId, discountCode).then((checkout) => {
    return checkout;
  });
}

export const discountRemove = async (checkoutId) => {
  return await client.checkout.removeDiscount(checkoutId).then((checkout) => {
    return checkout;
  });
}
