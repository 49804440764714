import React from 'react';

let discountPercent = .1;

const partnerName = window.location.href;
let partnerNameActual = partnerName.split("/").pop();

console.log(partnerNameActual);

if (partnerNameActual === "gympass" || partnerNameActual === "bcbs") {
  discountPercent = .15;
}

if (partnerNameActual === "bank-of-america") {
  discountPercent = .20;
}

//const bcbsCheck= window.location.href.includes('bcbs');


console.log(discountPercent);


function lineItems(product, iii){

  let price;
  // let regex = /(EX-5\s)/g;
  // let rowRegex = "Row Connected";
  // let rowSRegex = "Row-s Connected";
// console.log(product.variant.price)
// console.log(product.title);

  if (product.title.includes('FitPass')) {
    price = product.variant.price;
  // } else if (product.title.includes('EX-3') && partnerNameActual !== "gympass"){
  //   //console.log("AA: "+ (product.variant.price - product.variant.price * .20).toFixed(2));
  //   price = (product.variant.price - product.variant.price * .20).toFixed(2);
  // }else if(product.title.match(regex) && partnerNameActual !== "gympass"){
  //   price = (product.variant.price - product.variant.price * .20).toFixed(2);
  // }else if(product.title.match(rowRegex) && partnerNameActual !== "gympass"){
  //   price = (product.variant.price - product.variant.price * .20).toFixed(2);
  // }else if(product.title.match(rowSRegex) && partnerNameActual !== "gympass"){
  //   price = (product.variant.price - product.variant.price * .20).toFixed(2);
   }else {
    price = (product.variant.price - product.variant.price * discountPercent).toFixed(2);
  }

  return price;
}

export default function Cart({ cart, showCartItems, totalSavings, subTotal, emptyCart, checkCart }) {

  //price = discountPercent * 1;  fake
  //console.log("A: ", cart.lineItems[0].title ); - works but also fake
  // cart.lineItems.foreach(product => console.log(product));
  
    return (
        <div className="cart-container">
        <div className="cart-wrapper">
          <div className="close-cart-btn-container">
            <div className="close-cart-btn" onClick={() => showCartItems()}>
            <svg className="h-12 w-12 fill-current text-grey hover:text-grey-darkest" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"></path></svg>
            </div>
          </div>
          <ul>
            {cart.lineItems.length > 0
              ? cart.lineItems.map((item, index) => (
                  <li className="cart-item" key={index}>
                    <img
                      src={item.variant.image.src}
                      alt={
                        item.variant.image.alt ? item.variant.image.alt : "Echelon Product"
                      }
                    />

                    <div className="item-price-container">
                      <span className="item-title">{item.title.toLowerCase().includes('shipping') ? item.title.replace('Partner Product', '') : item.title.replace('Partner Product', '')} [{item.quantity}]</span>
                      <span className={`item-price ${item.title.includes('Apple') ? 'ipadProd' : ''}`}>
                        ${item.title.includes( 'Plan') || item.title.includes('Monthly FitPass') || item.title.includes('Membership') || item.title.includes('Premier') ? item.variant.price.amount : ((item.variant.price.amount - item.variant.price.amount * discountPercent).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </span>

                                                
                        
                        {item.variant.compareAtPrice ?
                        <span className="compareAt-price">{item.title.includes('BCBS Special') ? item.variant.compareAtPriceV2.amount : item.variant.price.amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</span>
                        : null }

                      </div>
                  </li>
                ))
              : "No Items In Cart"}
          </ul>
          <div className="totals">
            <p>Savings: ${totalSavings ? totalSavings.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : (0.00).toFixed(2)}</p>
            <p>Sub Total: ${subTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
            <span>Not including taxes and shipping costs.</span>

          </div>
          <div className="cart-btns-container">
            <button className="clear-cart" onClick={() => emptyCart()}>Clear</button>
            <button className="checkout" onClick={() => checkCart()}>Checkout</button>
          </div>
        </div>
      </div>
    )
}
