import React from "react";


const partnerName = window.location.href;
const bcbsCheck = window.location.href.includes('bcbs');
const boaCheck = window.location.href.includes('bank-of-america');


let partnerNameActual = partnerName.split("/").pop();
let showBanner;


if(partnerNameActual === 'perkspot' || partnerNameActual === 'gympass' || partnerNameActual === 'bank-of-america'){
    showBanner = 'gympassy';
}else{
    showBanner = 'gympassn';
}




export default function PromoBanner({ message }) {
    return (
        <div className="promo-banner-container" id={showBanner}>
            {message ? 
            <div className="promo-message">
                {bcbsCheck || boaCheck ? "Enjoy additional savings with the purchase of any Echelon connected product and any membership!" : message }
            </div> 
            : 
            null}
           
        </div>
    )
}

