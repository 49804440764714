import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import { request } from "graphql-request";
import { fetchPages } from "./shared/checkout";
import HomePage from "./components/HomePage";
import Partner from "./components/Partner";
import FourOFour from "./components/404";

function App() {
  const [partnerData, setPartnerData] = useState(null);

  useEffect(() => {
      fetchPages().then((response) => setPartnerData(response));
  }, []);

  return (
    <div className="App">
      <Router>
          {!partnerData ? (
            "Loading"
          ) : (
            <Switch>
              <Route exact path="/">
                <HomePage
                  partnerData={partnerData}
                />
              </Route>
                {partnerData.map(
                  ({ header, slug, freeShipping, partnerLogo, urlPrefix }) => {
                    let pathActual = `/affiliate/${slug}`;

                    if (urlPrefix) {
                      pathActual = `/${urlPrefix}/${slug}`;
                    }

                    return <Route key={slug} path={pathActual}>
                      <Partner
                        key={slug}
                        partner={slug}
                        header={header}
                        shipping={freeShipping}
                        logo={partnerLogo}
                      />
                    </Route>
                  }
                )}
                <Route path="/*">
                  <FourOFour />
                </Route>
            </Switch>
          )}
      </Router>
    </div>
  );
}

export default App;
